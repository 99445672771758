<script setup lang="ts">
import { computed, ref, defineEmits, reactive } from 'vue';
import type { SurveyProgressAnswer, SurveyQuestionModel } from '@/types/model';
import type { ISurveyButton, ISurveySlider, ITheSurveyMultipleChoiceComponent, ITheSurveyPageComponent } from '@/types/components';
import TheSurveyMultipleChoice from './TheSurveyMultipleChoice.vue';
import SurveyButton from './SurveyButton.vue';
import SurveySlider from './SurveySlider.vue';
import { useSurveyStore } from '@/store';
import { SurveyComponentQuestionTypeEnum } from '@/constants/survey';

const surveyModule = useSurveyStore();


const emit = defineEmits(['onNextClick', 'onDeclineClick', 'update:selectedValues']);

const multipleChoiceAnswers = ref<ITheSurveyMultipleChoiceComponent[]>([]);
const sliderAnswers = ref<ISurveySlider[]>([]);
const buttonAnswers = ref<ISurveyButton[]>([]);
const currentPage = computed(() => surveyModule.currentPage);

// Initialize selectedValues with empty objects for the multiple choice depths
let selectedValues = reactive({ 1: {}, 2: {} });
selectedValues[currentPage.value.renderingConfig.order] = {};

const isMultipleChoice = (question: SurveyQuestionModel) => {
  return (
    question.renderingConfig.type === SurveyComponentQuestionTypeEnum.choice &&
    question.permittedAnswers.length !== 0
  );
};

const isButton = (question: SurveyQuestionModel) => {
  return (
    question.renderingConfig.type === SurveyComponentQuestionTypeEnum.button &&
    question.permittedAnswers.length !== 0
  );
};

const isSlider = (question: SurveyQuestionModel) => {
  return question.renderingConfig.type === SurveyComponentQuestionTypeEnum.slider;
};

const validate = async () => {
  let valid = true;
  if (multipleChoiceAnswers.value) {
    const results = await Promise.all(
      multipleChoiceAnswers.value.map((mc) => mc.validate())
    );
    valid = results.every((valid) => valid);
  }
  return valid;
};

const getResponses = () => {
  let responses: SurveyProgressAnswer[] = [];

  let multiplechoiceAnswers: SurveyProgressAnswer[] = [];
  let sliderAnswersResponses: SurveyProgressAnswer[] = [];
  let buttonAnswersResponses: SurveyProgressAnswer[] = [];

  currentPage.value.questions.forEach((question) => {
    if (question.renderingConfig.type === SurveyComponentQuestionTypeEnum.choice) {
      multiplechoiceAnswers = multipleChoiceAnswers.value
        .map((mca) => mca.getResponses())
        .flat();
    } else if (question.renderingConfig.type === SurveyComponentQuestionTypeEnum.slider) {
      sliderAnswersResponses = sliderAnswers.value.map((sa) => sa.getResponse());
    } else if (question.renderingConfig.type === SurveyComponentQuestionTypeEnum.button) {
      buttonAnswersResponses = buttonAnswers.value.map((ba) => ba.getResponse());
    }
  });

  responses = [...multiplechoiceAnswers, ...sliderAnswersResponses, ...buttonAnswersResponses];

  return responses;
};

defineExpose({
  getResponses,
  validate
});
</script>

<template>
  <div v-for="question in currentPage.questions"
       class="row survey__question"
       :key="question.questionId">
    <div class="col">

      <!-- Question Image -->
      <template v-if="question.renderingConfig.imageUrl">
        <div class="row justify-center q-pb-xl">
          <q-img width="75%" :src="question.renderingConfig.imageUrl" />
        </div>
      </template>
      
      <!-- Question Header -->
      <template v-if="question.renderingConfig.showHeader">
        <h3 v-if="question.questionHeader"
            v-html="question.questionHeader"
            class="survey__question-header" />
      </template>
      
      <!-- Question Text -->
      <p v-if="question.questionText"
         v-html="question.questionText"
         class="survey__question-text" />
      
      <!-- Question Sub-Text -->
      <p v-if="question.questionSubText"
         v-html="question.questionSubText"
         class="survey__question-subtext" />
      
      <!-- Answer Options -->
      <div v-if="isMultipleChoice(question)" class="survey__answer">
        <TheSurveyMultipleChoice v-for="answer in question.permittedAnswers" v-model="selectedValues" :depth="1"
          :key="`${question.questionId}-${answer.answerId}`" :permittedAnswer="answer" :question="question"
          ref="multipleChoiceAnswers" />
      </div>
      
      <!-- Button(s) -->
      <template v-if="isButton(question)">
        <SurveyButton v-model="selectedValues"
                      @on-decline-click="emit('onDeclineClick')"
                      @on-next-click="emit('onNextClick')"
                      :question="question"
                      ref="buttonAnswers" />
      </template>

      <!-- Slider -->
      <template v-if="isSlider(question)">
        <SurveySlider v-model="selectedValues"
                      :question="question"
                      ref="sliderAnswers" />
      </template>
      
      <!-- Footer -->
      <p v-if="currentPage.footer"
           v-html="currentPage.footer" 
           class="survey__footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.survey {
  
  // Question
  &__question { // .row
    
    &:not(:last-child) {
      margin-block-end: 60px;
    }
    
    // Header
    &-header { // <h3>
      margin-block-end: 15px;
      
      &:empty {
        display: none;
      }
    }

    // Text
    &-text { // <p>
      font-size: map-get($font-sizes, '22');
      line-height: 30px;

      .italic { // <span>
        font-size: map-get($font-sizes, '18');
      }
    }
    
    // SubText
    &-subtext { // <p>
      font-size: map-get($font-sizes, '18');
      line-height: 30px;
    }
  }

  // Footer
  &__footer { // <p>
    color: $border-clr;
    text-align: center;
    padding-block-start: 50px;
  }
}
</style>